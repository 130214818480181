import {PlastischeChirurgieMain} from "./ch.plastische-chirurgie-zug/PlastischeChirurgieMain";

require('SCSS/index.scss')

/* ----------------------------------------------------------------
 * Version
 * --------------------------------------------------------------*/

console.log("Plastische Chirurgie Zug " + process.env.version + (process.env.production ? "" : " (dev-build)"));

/* ----------------------------------------------------------------
 * Initialize the App
 * --------------------------------------------------------------*/

window.onload = () => {
    PlastischeChirurgieMain.init();
}
