import {UIComponent} from "@webfruits/core";
import {CoreState} from "../../core/CoreState";
import {SVGComponent} from "@webfruits/toolbox/dist/components/svg/SVGComponent";
import {CoreAssets} from "../../core/CoreAssets";
import {AppConfig} from "../../config/AppConfig";
import {gsap} from "gsap";
import {ShadowEffects} from "../../styles/ShadowEffects";

/******************************************************************
 * MenuButton
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class MenuButton extends UIComponent {

    /******************************************************************
     * Properties
     *****************************************************************/

    private _menuButton: SVGComponent;
    private _hamburgerBars: SVGElement;
    private _closeBars: SVGElement;
    private _backBars: SVGElement;

    /******************************************************************
     * Constructor
     *****************************************************************/

    constructor() {
        super("menu-button");
        this.initMenuButton();
        this.initListeners();
        this.updateMenuButton(false);
        CoreState.MENU_STATE.onChangeSignal.add(() => this.onMenuStateChanged());
    }

    /******************************************************************
     * Public Methodes
     *****************************************************************/

    public updateStyles() {
        this.applyStyle({
            display: AppConfig.HIDE_NAVIGATION ? "inline-block" : "none"
        })
        this._menuButton.applyStyle({
            cursor: "pointer",
            fontSize: 0,
            lineHeight: 0,
            borderRadius: 100,
            boxShadow: ShadowEffects.META_BUTTON_SHADOW
        })
    }

    /******************************************************************
     * Private Methodes
     *****************************************************************/

    private initMenuButton() {
        this._menuButton = new SVGComponent(CoreAssets.MENU_BUTTON);
        this._hamburgerBars = this._menuButton.getElementByID("hamburger-bars");
        this._closeBars = this._menuButton.getElementByID("close-bars");
        this._backBars = this._menuButton.getElementByID("back-bars");
        this.addChild(this._menuButton);
    }

    private initListeners() {
        this._menuButton.addNativeListener("click", () => this.onClicked());
    }

    private updateMenuButton(animate: boolean) {
        const dx = 50;
        const duration = animate ? AppConfig.MENU_SHOW_HIDE_DURATION : 0;
        let hamburgerBarsX = 0;
        let closeBarsX = -dx;
        let backBarsX = -dx * 2;
        switch (CoreState.MENU_STATE.getValue()) {
            case "primary":
                hamburgerBarsX = dx;
                closeBarsX = 0;
                backBarsX = -dx;
                break;
            case "secondary":
                hamburgerBarsX = dx * 2;
                closeBarsX = dx;
                backBarsX = 0;
                break;
        }
        gsap.to(this._hamburgerBars, {
            duration: duration,
            x: hamburgerBarsX,
            ease: "power4.out"
        })
        gsap.to(this._closeBars, {
            duration: duration,
            x: closeBarsX,
            ease: "power4.out"
        })

        gsap.to(this._backBars, {
            duration: duration,
            x: backBarsX,
            ease: "power4.out"
        })
    }

    /******************************************************************
     * Events
     *****************************************************************/

    private onClicked() {
        switch (CoreState.MENU_STATE.getValue()) {
            case "primary":
                CoreState.MENU_STATE.setValue("closed");
                break;
            case "closed":
                CoreState.MENU_STATE.setValue("primary");
                break;
            case "secondary":
                CoreState.MENU_STATE.setValue("primary");
                break;
        }
    }

    private onMenuStateChanged() {
        this.updateMenuButton(true);
    }
}
