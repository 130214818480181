/******************************************************************
 * Size
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class Size {

    static get APP_WIDTH() {
        return window.innerWidth;
    }

    static get APP_HEIGHT() {
        return window.innerHeight;
    }

    static get IS_MOBILE_MODE() {
        // keep in sync with kkt-breakpoints.css MOBILE_MODE
        return Size.APP_WIDTH <= 768;
    }

}
