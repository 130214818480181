import {StateValue} from "@webfruits/toolbox/dist/state/StateValue";
import {MenuStateTypes} from "../types/MenuStateTypes";
import {AppConfig} from "../config/AppConfig";

/******************************************************************
 * CoreState
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class CoreState {
    static readonly SECONDARY_NAV_VISIBLE = new StateValue<boolean>(false);
    static readonly HOMEPAGE_TEASER_ID = new StateValue<string>();
    static readonly MENU_STATE = new StateValue<MenuStateTypes>(AppConfig.INITIAL_MENU_STATE);
    static readonly PRIMARY_NAV_SCROLL_Y = new StateValue<number>(0);
}
