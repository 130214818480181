import {UIController} from "../UIController";
import {PrimaryNavItemController} from "./modules/PrimaryNavItemController";
import {UIComponent} from "@webfruits/core";
import {CoreState} from "../../core/CoreState";
import {Draggable} from "gsap/Draggable";
import {gsap} from "gsap";
import {AppConfig} from "../../config/AppConfig";
import {Size} from "../../styles/Size";
import {DOMUtils} from "@webfruits/toolbox/dist/utils/DOMUtils";
import {PromisedDelay} from "@webfruits/toolbox/dist/timer/PromisedDelay";


/******************************************************************
 * NavigationController
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class NavigationController extends UIController {

    /******************************************************************
     * Properties
     *****************************************************************/

    private _logoElement: HTMLElement;
    private _mainNavElement: HTMLElement;
    private _latestCalcedContentHeight: number = 0;
    private _metaNavComponent: UIComponent;
    private _draggable: Draggable;
    private _scrollWrapper: HTMLElement;
    private _needScrolling: boolean;
    private _isPointerOverNavigationLayer: boolean;
    private _isUpdateVisibilityComplete: boolean;

    /******************************************************************
     * Constructor
     *****************************************************************/

    constructor(element: HTMLElement) {
        super(element);
        this.initElements();
        this.initPrimaryButtons();
        this.initDraggable();
        this.initScrollListener();
        this.initListeners();
        CoreState.MENU_STATE.onChangeSignal.add(() => this.onMenuStateChanged());
    }

    /******************************************************************
     * Public Methodes
     *****************************************************************/

    public async updateStyles() {
        await PromisedDelay.waitUntilValid(() => {
            const valid = this.calcUsedContentHeight() == this._latestCalcedContentHeight
            this._latestCalcedContentHeight = this.calcUsedContentHeight()
            return valid;
        })
        this._needScrolling = this.view.offsetHeight < this._latestCalcedContentHeight;
        this._metaNavComponent.applyStyle({
            position: this._needScrolling ? "relative" : "absolute",
            left: this._needScrolling ? 0 : undefined,
            right: this._needScrolling ? 0 : undefined,
        })
        this._draggable.update();
        this.updateDraggableBounds();
        this.updateScrollYState();
        this.updateVisibility(false);
    }

    /******************************************************************
     * Private Methodes
     *****************************************************************/

    private initElements() {
        this._logoElement = this.getElementByClass("kkt-navigation-logo");
        this._mainNavElement = this.getElementByClass("kkt-navigation-main");
        this._scrollWrapper = this.getElementByClass("kkt-navigation-scroll-wrapper");
        this._metaNavComponent = new UIComponent(this.getElementByClass("kkt-navigation-meta"));
    }

    private initPrimaryButtons() {
        const primaryNavLinkElements = this.getElementsByClass("kkt-navigation-main-primary-item");
        primaryNavLinkElements.forEach((primaryNavLinkElement: HTMLLIElement) => {
            new PrimaryNavItemController(primaryNavLinkElement);
        })
    }

    private initDraggable() {
        this._draggable = Draggable.create(this._scrollWrapper, {
            type: "y",
            throwProps: true,
            edgeResistance: 0.9,
            maxDuration: 0.5,
            cursor: "pointer",
            onDragStart: () => {
                this.interactive = false
            },
            onDragEnd: () => {
                this.interactive = true
            },
            onDrag: () => {
                this.updateScrollYState();
            }
        })[0];
        this.updateDraggableBounds();
    }

    private initScrollListener() {
        this.addNativeListener("wheel", (e: WheelEvent) => this.onScrolled(e));
    }

    private initListeners() {
        this.addNativeListener("mouseenter", () => this.onMouseEntered());
        this.addNativeListener("mouseleave", () => this.onMouseLeft());
        window.addEventListener("click", () => this.onViewportClicked());
    }

    private calcUsedContentHeight(): number {
        const padding = parseInt(window.getComputedStyle(this._scrollWrapper).paddingBottom);
        const logoHeight = DOMUtils.calcElementHeight(this._logoElement);
        const mainNavHeight = DOMUtils.calcElementHeight(this._mainNavElement);
        const metaNavHeight = DOMUtils.calcElementHeight(this._metaNavComponent.view);
        return logoHeight + metaNavHeight + mainNavHeight + padding * 2;
    }

    private updateVisibility(animate: boolean) {
        let x = "0%";
        this.interactive = true;
        this._draggable.enabled(this._needScrolling);
        if (AppConfig.HIDE_NAVIGATION) {
            switch (CoreState.MENU_STATE.getValue()) {
                case "closed":
                    x = "-100%";
                    this.interactive = false;
                    this._draggable.enabled(false);
                    break;
                case "secondary":
                    x = "-100%";
                    this._draggable.enabled(false);
                    break;
                case "primary":
                    x = "0%";
                    break;
            }
        } else {
            CoreState.MENU_STATE.setValue("primary");
        }
        this._isUpdateVisibilityComplete = false;
        gsap.killTweensOf(this.view);
        gsap.to(this.view, {
            duration: animate ? AppConfig.MENU_SHOW_HIDE_DURATION : 0,
            x: x,
            ease: "power4.out",
            onComplete: () => {
                this._isUpdateVisibilityComplete = true;
                if (CoreState.MENU_STATE.isValue("closed")) {
                    this.resetScrollWrapper();
                }
            }
        })
        this.updateScrollYState();
    }

    private updateScrollYState() {
        CoreState.PRIMARY_NAV_SCROLL_Y.setValue(gsap.getProperty(this._scrollWrapper, "y") as number);
    }

    private updateDraggableBounds() {
        this._draggable.applyBounds({
            minX: 0,
            minY: Size.APP_HEIGHT - this._scrollWrapper.offsetHeight,
            maxX: 0,
            maxY: 0
        })
    }

    private resetScrollWrapper() {
        gsap.set(this._scrollWrapper, {
            y: 0
        })
    }

    /******************************************************************
     * Events
     *****************************************************************/

    private onMenuStateChanged() {
        this.updateVisibility(true);
    }

    private onScrolled(e: WheelEvent) {
        if (!this._needScrolling) return;
        const currentY = gsap.getProperty(this._scrollWrapper, "y") as number;
        let nextY = currentY - e.deltaY;
        if (nextY > 0) {
            nextY = 0;
        }
        const scrollOffset = this.view.offsetHeight - this.calcUsedContentHeight();
        if (nextY < scrollOffset) {
            nextY = scrollOffset;
        }
        gsap.set(this._scrollWrapper, {
            y: nextY
        })
        this.updateScrollYState();
    }

    private async onViewportClicked() {
        if (!this._isUpdateVisibilityComplete || this._isPointerOverNavigationLayer) return;
        if (AppConfig.HIDE_NAVIGATION) {
            switch (CoreState.MENU_STATE.getValue()) {
                case "primary":
                case "secondary":
                    CoreState.MENU_STATE.setValue("closed");
                    break;
            }
        }
    }

    private onMouseEntered() {
        this._isPointerOverNavigationLayer = true;
    }

    private onMouseLeft() {
        this._isPointerOverNavigationLayer = false;
    }

}
