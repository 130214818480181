import {UIController} from "../../UIController";
import {Draggable} from "gsap/Draggable";
import {UIComponent} from "@webfruits/core";
import {gsap} from "gsap";
import {CoreState} from "../../../core/CoreState";
import {PromisedDelay} from "@webfruits/toolbox/dist/timer/PromisedDelay";
import {AppConfig} from "../../../config/AppConfig";

/******************************************************************
 * GoogleReviewController
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class GoogleReviewController extends UIController {

    /******************************************************************
     * Properties
     *****************************************************************/

    private _draggable: Draggable;
    private _nextButton: UIComponent;
    private _prevButton: UIComponent;

    /******************************************************************
     * Constructor
     *****************************************************************/

    constructor(element) {
        super(element);
        this.initDraggable();
        this.initButtons();
        this.showRandomSlide();
        CoreState.MENU_STATE.onChangeSignal.add(() => this.onMenuStateChanged());
    }

    /******************************************************************
     * Public Methodes
     *****************************************************************/

    public updateStyles() {
        this.updateDraggableBounds();
        this._draggable.update();
        this.showSlide(this.getCurrentSlideID());
    }

    /******************************************************************
     * Private Methodes
     *****************************************************************/

    private initDraggable() {
        this._draggable = Draggable.create(this.getElementByTag("ul"), {
            type: "x",
            throwProps: true,
            maxDuration: 0.3,
            edgeResistance: 0.9,
            cursor: "pointer",
            snap: (value) => {
                return Math.round(value / this.calcReviewWidth()) * this.calcReviewWidth() - this.calcReviewPadding();
            },
            onDragStart: () => {
                this.interactive = false
            },
            onDragEnd: () => {
                this.interactive = true
            },
        })[0];
        this.updateDraggableBounds();
    }

    private initButtons() {
        this._prevButton = new UIComponent(this.getElementByClass("kkt-navigation-google-reviews-reviews-buttons-prev"));
        this._nextButton = new UIComponent(this.getElementByClass("kkt-navigation-google-reviews-reviews-buttons-next"));
        this._prevButton.addNativeListener("click", () => this.onPrevButtonClicked());
        this._nextButton.addNativeListener("click", () => this.onNextButtonClicked());
    }

    private showSlide(slideID: number, instantly: boolean = false) {
        if (slideID < 0) {
            slideID = this.numReviews() - 1;
        }
        if (slideID >= this.numReviews()) {
            slideID = 0;
        }
        gsap.to(this._draggable.target, {
            duration: instantly ? 0 : 0.7,
            x: -slideID * this.calcReviewWidth() - this.calcReviewPadding(),
            ease: "power4.out",
            onUpdate: () => {
                this._draggable.update()
            }
        })
    }

    private calcReviewPadding(): number {
        return parseFloat(window.getComputedStyle(this.getElementByTag("li")).paddingLeft)
    }

    private calcReviewWidth(): number {
        return this.getElementByTag("li").offsetWidth;
    }

    private numReviews(): number {
        return this.getElementsByTag("li").length;
    }

    private getCurrentSlideID(): number {
        return Math.abs(Math.round(this._draggable.x / this.calcReviewWidth()));
    }

    private updateDraggableBounds() {
        this._draggable.applyBounds({
            minX: -this.calcReviewPadding(),
            minY: 0,
            maxX: -this.calcReviewWidth() * (this.numReviews() - 1) - this.calcReviewPadding(),
            maxY: 0
        })
    }

    private showRandomSlide() {
        this.showSlide(Math.round(Math.random() * this.numReviews()), false);
    }

    /******************************************************************
     * Events
     *****************************************************************/

    private onNextButtonClicked() {
        this.showSlide(this.getCurrentSlideID() + 1);
    }

    private onPrevButtonClicked() {
        this.showSlide(this.getCurrentSlideID() - 1);
    }

    private async onMenuStateChanged() {
        if (CoreState.MENU_STATE.isValue("primary")) {
            await PromisedDelay.wait(AppConfig.MENU_SHOW_HIDE_DURATION * 0.5);
            this.showSlide(this.getCurrentSlideID() + 1);
        }
    }
}
