/******************************************************************
 * Color
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class Color {

    static KKT_WHITE = "#FFFFFF";
    static KKT_BACKGROUND = "#F4F0ED";
    static KKT_LIGHT_BROWN = "#E7E0DB";
    static KKT_BROWN = "#A49389";
    static KKT_GRAY = "#707070";
    static KKT_DARK_BLUE = "#2F4858";
    static KKT_SEMI_BLUE = "#606376";

}
