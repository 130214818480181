import {UIController} from "../UIController";
import {MenuButton} from "../../components/menubutton/MenuButton";

/******************************************************************
 * HeaderUIController
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class HeaderUIController extends UIController {

    /******************************************************************
     * Properties
     *****************************************************************/

    private _menuButton: MenuButton;

    /******************************************************************
     * Constructor
     *****************************************************************/

    constructor(rootElement: HTMLElement) {
        super(rootElement);
        this.initMenuButton();
    }

    /******************************************************************
     * Public Methodes
     *****************************************************************/

    public updateStyles() {
        // see kkt-header-ui.scss for further styles (positioning)
        this.applyStyle({
            position: "fixed",
            zIndex: 10,
            top: 0
        })
    }

    /******************************************************************
     * Private Methodes
     *****************************************************************/

    private initMenuButton() {
        this._menuButton = new MenuButton();
        this.addChild(this._menuButton);
    }

    /******************************************************************
     * Events
     *****************************************************************/



}
