import {NavigationController} from "./navigation/NavigationController";
import {HomepageController} from "./homepage/HomepageController";
import {HeaderUIController} from "./headerui/HeaderUIController";
import {ContactButtonController} from "./contactbutton/ContactButtonController";
import {ContactBoxController} from "./contactbox/ContactBoxController";
import {ArticleHeaderController} from "./articleheader/ArticleHeaderController";
import {AccordionController} from "./accordion/AccordionController";
import {ScrollEffectController} from "./effects/ScrollEffectController";
import {GoogleReviewController} from "./navigation/modules/GoogleReviewController";
import {LanguageSelectorController} from "./language/LanguageSelectorController";

/******************************************************************
 * ControllerFactory
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class ControllerFactory {

    private static CONTROLLER_DICTIONARY = [
        {selector: "#kkt-header-ui", ControllerClass: HeaderUIController},
        {selector: "#kkt-navigation", ControllerClass: NavigationController},
        {selector: ".kkt-navigation-google-reviews", ControllerClass: GoogleReviewController},
        {selector: "#kkt-homepage", ControllerClass: HomepageController},
        {selector: "#kkt-article-header", ControllerClass: ArticleHeaderController},
        {selector: ".kkt-phone-button", ControllerClass: ContactButtonController},
        {selector: ".kkt-mail-button", ControllerClass: ContactButtonController},
        {selector: ".kkt-internallink-button", ControllerClass: ContactButtonController},
        {selector: ".kkt-location-button", ControllerClass: ContactButtonController},
        {selector: ".kkt-contactbox", ControllerClass: ContactBoxController},
        {selector: ".kkt-language-selector", ControllerClass: LanguageSelectorController},
        {selector: ".kkt-accordion", ControllerClass: AccordionController},

        {selector: ".kkt-article-header-content-left > h1", ControllerClass: ScrollEffectController},
        {selector: ".kkt-article-header-content-left > h2", ControllerClass: ScrollEffectController},
        {selector: ".kkt-article-header-content-left > nav", ControllerClass: ScrollEffectController},
        {selector: ".kkt-article-header-content-right-abstract", ControllerClass: ScrollEffectController},
        {selector: ".kkt-article-section-headline > h2", ControllerClass: ScrollEffectController},
        {selector: ".kkt-article-section-headline > h3", ControllerClass: ScrollEffectController},
        {selector: ".kkt-vitealist-entry", ControllerClass: ScrollEffectController},
        {selector: ".kkt-text", ControllerClass: ScrollEffectController},
        {selector: ".kkt-quote", ControllerClass: ScrollEffectController},
        {selector: ".kkt-image", ControllerClass: ScrollEffectController},
        {selector: ".kkt-accordion-entry", ControllerClass: ScrollEffectController},
        {selector: ".kkt-serviceteasers-entry", ControllerClass: ScrollEffectController},
        {selector: ".kkt-reviews-entry-image", ControllerClass: ScrollEffectController},
        {selector: ".kkt-reviews-entry-content > blockquote", ControllerClass: ScrollEffectController},
        {selector: ".kkt-reviewstars", ControllerClass: ScrollEffectController},
        {selector: ".kkt-google-review-stars", ControllerClass: ScrollEffectController},
        {selector: ".kkt-openings-image", ControllerClass: ScrollEffectController},
        {selector: ".kkt-openings-content", ControllerClass: ScrollEffectController},
        {selector: ".kkt-serviceinfos-entry", ControllerClass: ScrollEffectController},
        {selector: ".kkt-contact-compact-image", ControllerClass: ScrollEffectController},
        {selector: ".kkt-contactbox", ControllerClass: ScrollEffectController},
        {selector: ".kkt-imagegallery-entry-image", ControllerClass: ScrollEffectController},
        {selector: ".kkt-imagegallery-entry-content", ControllerClass: ScrollEffectController},
        {selector: ".kkt-logogrid-entry", ControllerClass: ScrollEffectController},
        {selector: ".kkt-patientteasers-entry", ControllerClass: ScrollEffectController},
        {selector: ".kkt-patientteasers-entry-content-name", ControllerClass: ScrollEffectController},
        {selector: ".kkt-patientteasers-entry-content-operation", ControllerClass: ScrollEffectController},
        {selector: ".kkt-contact-indiviual-entry", ControllerClass: ScrollEffectController},
        {selector: ".kkt-factlist-entry", ControllerClass: ScrollEffectController}
    ];

    static createComponentControllers() {
        ControllerFactory.CONTROLLER_DICTIONARY.forEach(entry => {
            Array.from(document.querySelectorAll(entry.selector)).forEach((element: HTMLElement) => {
                new entry.ControllerClass(element);
            });
        });
    }

}
