/******************************************************************
 * CoreAssets
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class CoreAssets {

    static PRIMARY_NAV_TEASER_POINTER = require("ASSETS/ui/HeroPointer.svg");
    static PRIMARY_NAV_TEASER_POINTER_SELECTED = require("ASSETS/ui/HeroPointerActive.svg");

    static MENU_BUTTON = require("ASSETS/ui/MobileMenuButton.svg");
    static PHONE_BUTTON = require("ASSETS/ui/PhoneButton.svg");
    static MAIL_BUTTON = require("ASSETS/ui/MailButton.svg");
    static LOCATION_BUTTON = require("ASSETS/ui/LocationButton.svg");
    static INTERNAL_LINK_BUTTON = require("ASSETS/ui/InternalLinkButton.svg");

    static PLUS_ICON = require("ASSETS/ui/PlusIcon.svg");
}
