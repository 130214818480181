import {UIComponent} from "@webfruits/core";
import {StateValue} from "@webfruits/toolbox/dist/state/StateValue";
import {TouchStateType} from "../types/TouchStateType";
import {Signal} from "@webfruits/core/dist/signal/Signal";

/******************************************************************
 * PointerEventsController
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class PointerEventsController {

    /******************************************************************
     * Properties
     *****************************************************************/

    private _preventDefaultClick: boolean;
    private _touchState = new StateValue<TouchStateType>("none")

    public onClickSignal = new Signal();
    public onOverSignal = new Signal();
    public onOutSignal = new Signal();

    /******************************************************************
     * Constructor
     *****************************************************************/

    constructor(private _listenerComponent: UIComponent) {
        this.initListeners();
    }

    /******************************************************************
     * Public Methodes
     *****************************************************************/

    set preventDefaultClick(value: boolean) {
        this._preventDefaultClick = value;
    }

    get isTouchEvent(): boolean {
        return !this._touchState.isValue("none");
    }

    /******************************************************************
     * Private Methodes
     *****************************************************************/

    private initListeners() {
        this._listenerComponent.addNativeListener("touchstart", () => this.onTouchStart());
        this._listenerComponent.addNativeListener("touchmove", () => this.onTouchMove());
        this._listenerComponent.addNativeListener("touchend", () => this.onTouchEnd());
        this._listenerComponent.addNativeListener("mouseenter", () => this.onPointerOver());
        this._listenerComponent.addNativeListener("mouseleave", () => this.onPointerOut());
        this._listenerComponent.addNativeListener("click", (e: MouseEvent) => this.onPointerClicked(e));
    }

    /******************************************************************
     * Events
     *****************************************************************/

    private onPointerOver() {
        if (this._touchState.getValue() == "start") return;
        this.onOverSignal.dispatch();
    }

    private onPointerOut() {
        this.onOutSignal.dispatch();
    }

    private onPointerClicked(e: MouseEvent) {
        this.onClickSignal.dispatch();
        e.stopPropagation();
        if (this._preventDefaultClick) {
            e.preventDefault();
        }
    }

    private onTouchStart() {
        this._touchState.setValue("start");
        this.onOverSignal.dispatch();
    }

    private onTouchMove() {
        this._touchState.setValue("move");
        this.onOutSignal.dispatch();
    }

    private onTouchEnd() {
        this._touchState.setValue("end");
        this.onOutSignal.dispatch();
    }

}
