import {UIController} from "../UIController";
import {AccordionEntryController} from "./AccordionEntryController";

/******************************************************************
 * AkkordionController
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class AccordionController extends UIController {

    /******************************************************************
     * Properties
     *****************************************************************/

    /******************************************************************
     * Constructor
     *****************************************************************/

    constructor(rootElement: HTMLElement) {
        super(rootElement);
        this.initEntryControllers();
    }

    /******************************************************************
     * Public Methodes
     *****************************************************************/

    // no public methodes yet

    /******************************************************************
     * Private Methodes
     *****************************************************************/

    private initEntryControllers() {
        this.getElementsByClass("kkt-accordion-entry").forEach((entryElement) => {
            new AccordionEntryController(entryElement);
        });
    }

    /******************************************************************
     * Events
     *****************************************************************/

    // no events yet

}
