import {UIController} from "../UIController";

/******************************************************************
 * ContactBoxController
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class ContactBoxController extends UIController {

    /******************************************************************
     * Properties
     *****************************************************************/

    private _entries: HTMLElement[];

    /******************************************************************
     * Constructor
     *****************************************************************/

    constructor(rootElement: HTMLElement) {
        super(rootElement);
        this.initElements();
    }

    /******************************************************************
     * Public Methodes
     *****************************************************************/

    // no public methodes yet

    /******************************************************************
     * Private Methodes
     *****************************************************************/

    private initElements() {
        this._entries = this.getElementsByClass("kkt-contactbox-main-entry");
        this._entries.forEach((entry: HTMLElement) => {
            const nameButton = entry.getElementsByClassName("kkt-contactbox-main-entry-name")[0] as HTMLElement;
            nameButton.addEventListener("click", () => this.onNameButtonClicked(entry));
        })
    }

    /******************************************************************
     * Events
     *****************************************************************/

    private onNameButtonClicked(selectedEntry: HTMLElement) {
        this._entries.forEach((entry: HTMLElement) => {
            entry.dataset.showcontent = entry == selectedEntry ? "true" : "false"
        })
    }
}
