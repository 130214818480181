import {UIController} from "../UIController";
import {StateValue} from "@webfruits/toolbox/dist/state/StateValue";
import {gsap} from "gsap";
import {CoreState} from "../../core/CoreState";
import {AppConfig} from "../../config/AppConfig";
import {SVGComponent} from "@webfruits/toolbox/dist/components/svg/SVGComponent";

/******************************************************************
 * LanguageSelectorController
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class LanguageSelectorController extends UIController {

    /******************************************************************
     * Properties
     *****************************************************************/

    private _buttonElements: HTMLElement[];
    private _displayState = new StateValue<"opened" | "closed">("closed");
    private _dropDownIndicator: SVGComponent;
    private _dropDownIndicatorArrow: SVGElement;

    /******************************************************************
     * Constructor
     *****************************************************************/

    constructor(rootElement: HTMLElement) {
        super(rootElement);
        this.initButtonElements();
        this.initDropDownIndicator();
        this.initListeners();
        CoreState.MENU_STATE.onChangeSignal.add(() => this.onMenuStateChanged());
    }

    /******************************************************************
     * Public Methodes
     *****************************************************************/

    public updateStyles() {
        this._dropDownIndicator.applyStyle({
            position: "absolute",
            cursor: "pointer",
            top: 7,
            left: 33
        })
        gsap.to(this.view, {
            duration: AppConfig.MENU_SHOW_HIDE_DURATION,
            x: CoreState.MENU_STATE.isValue("closed") ? -20 : 0,
            opacity: CoreState.MENU_STATE.isValue("closed") ? 0 : 1,
            pointerEvents: CoreState.MENU_STATE.isValue("closed") ? "none" : "all",
            ease: "power4.out"
        })
        gsap.to(this._buttonElements, {
            duration: 0.4,
            stagger: 0.02,
            top: (id) => {
                return id * (this._displayState.isValue("opened") ? 45 : 0);
            },
            opacity: (id) => {
                return (this._displayState.isValue("opened") || this._buttonElements[id].dataset.isCurrentLang == "true") ? 1 : 0
            },
            ease: "power4.out"
        })
        gsap.to(this._dropDownIndicatorArrow, {
            duration: 0.4,
            transformOrigin: "40% 60%",
            rotation: (this._displayState.isValue("opened") ? 180 : 0) - 45,
            ease: "power4.out"
        })
    }

    /******************************************************************
     * Private Methodes
     *****************************************************************/

    private initButtonElements() {
        this._buttonElements = this.getElementsByTag("a");
        this.reorderButtonElements();
    }

    private initDropDownIndicator() {
        this._dropDownIndicator = new SVGComponent(require("ASSETS/ui/DropDownIndicator.svg"));
        this._dropDownIndicatorArrow = this._dropDownIndicator.getElementByID("arrow");
        this.addChild(this._dropDownIndicator);
    }

    private initListeners() {
        this._buttonElements.forEach((buttonElement: HTMLAnchorElement) => {
            buttonElement.addEventListener("click", (e: MouseEvent) => this.onLanguageClicked(e, buttonElement));
        })
        this._displayState.onChangeSignal.add(() => this.onDisplayStateChanged());
        this._dropDownIndicator.addNativeListener("click", (e: MouseEvent) => this.onDropDownIndicatorClicked(e));
        window.addEventListener("click", () => this.onWindowClicked());
    }

    private reorderButtonElements() {
        const currentButton = this._buttonElements.filter(button => button.dataset.isCurrentLang == "true")[0];
        this._buttonElements.forEach((buttonElement) => {
            buttonElement.remove();
        })
        this.view.appendChild(currentButton);
        this._buttonElements.forEach((buttonElement) => {
            if (buttonElement != currentButton) {
                this.view.appendChild(buttonElement);
            }
        })
        this._buttonElements = this.getElementsByTag("a");
    }

    /******************************************************************
     * Events
     *****************************************************************/

    private onLanguageClicked(e: MouseEvent, buttonElement: HTMLAnchorElement) {
        e.stopPropagation();
        if (this._displayState.isValue("closed")) {
            e.preventDefault();
            this._displayState.setValue("opened");
        } else {
            if (buttonElement.dataset.isCurrentLang == "true") {
                e.preventDefault();
                this._displayState.setValue("closed");
            }
        }
    }

    private onDisplayStateChanged() {
        this.updateStyles();
    }

    private onWindowClicked() {
        if (this._displayState.isValue("opened")) {
            this._displayState.setValue("closed");
        }
    }

    private onMenuStateChanged() {
        this.updateStyles();
    }

    private onDropDownIndicatorClicked(e: MouseEvent) {
        e.stopPropagation();
        if (this._displayState.isValue("opened")) {
            this._displayState.setValue("closed");
        } else {
            this._displayState.setValue("opened");
        }
    }
}
