import {UIController} from "../../UIController";
import {gsap} from "gsap";
import {Color} from "../../../styles/Color";
import {Signal} from "@webfruits/core/dist/signal/Signal";
import {PointerEventsController} from "../../../utils/PointerEventsController";

/******************************************************************
 * BasicNavigationItemController
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class BasicNavigationItemController extends UIController {

    /******************************************************************
     * Properties
     *****************************************************************/

    private _anchorSpanElement: HTMLSpanElement;
    private _pointerEventsController: PointerEventsController;

    public onClickSignal = new Signal();
    public onEnterSignal = new Signal();
    public onLeaveSignal = new Signal();

    /******************************************************************
     * Constructor
     *****************************************************************/

    constructor(navItemElement: HTMLLIElement) {
        super(navItemElement);
        this.initElements();
        this.initListeners();
    }

    /******************************************************************
     * Public Methodes
     *****************************************************************/

    set preventDefaultClick(value: boolean) {
        this._pointerEventsController.preventDefaultClick = value;
    }

    get isTouchEvent(): boolean {
        return this._pointerEventsController.isTouchEvent;
    }

    get spanWidthOffset(): number {
        return this._anchorSpanElement.offsetWidth - this.view.offsetWidth;
    }

    /******************************************************************
     * Private Methodes
     *****************************************************************/

    private initElements() {
        this._anchorSpanElement = this.getElementByTag("span") as HTMLSpanElement;
    }

    private initListeners() {
        this._pointerEventsController = new PointerEventsController(this);
        this._pointerEventsController.onOverSignal.add(() => this.onPointerOver());
        this._pointerEventsController.onOutSignal.add(() => this.onPointerOut());
        this._pointerEventsController.onClickSignal.add(() => this.onPointerClicked());
    }

    private animateHover() {
        gsap.to(this._anchorSpanElement, {
            duration: 0.3,
            x: 10,
            color: Color.KKT_DARK_BLUE,
            ease: "power4.out"
        })
    }

    private animateHoverLeft() {
        gsap.to(this._anchorSpanElement, {
            duration: 0.3,
            x: 0,
            color: null,
            ease: "power4.out"
        })
    }

    /******************************************************************
     * Events
     *****************************************************************/

    private onPointerOver() {
        this.animateHover();
        this.onEnterSignal.dispatch();
    }

    private onPointerOut() {
        this.animateHoverLeft();
        this.onLeaveSignal.dispatch();
    }

    private onPointerClicked() {
        this.animateHoverLeft();
        this.onClickSignal.dispatch();
    }
}
