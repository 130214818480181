import {UIController} from "../UIController";
import {ContactButtonType} from "../../types/ContactButtonType";
import {SVGComponent} from "@webfruits/toolbox/dist/components/svg/SVGComponent";
import {CoreAssets} from "../../core/CoreAssets";
import {UIComponent} from "@webfruits/core";
import {ShadowEffects} from "../../styles/ShadowEffects";
import {TextField} from "@webfruits/toolbox/dist/components/text/TextField";
import {Color} from "../../styles/Color";
import {PromisedDelay} from "@webfruits/toolbox/dist/timer/PromisedDelay";
import {Size} from "../../styles/Size";
import {gsap} from "gsap";
import {AppConfig} from "../../config/AppConfig";
import {CoreState} from "../../core/CoreState";

/******************************************************************
 * ContactButtonController
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class ContactButtonController extends UIController {

    /******************************************************************
     * Properties
     *****************************************************************/

    private readonly _type: ContactButtonType;
    private _button: SVGComponent;
    private _anchor: UIComponent<HTMLAnchorElement>;
    private _labelField: TextField;

    /******************************************************************
     * Constructor
     *****************************************************************/

    constructor(rootElement: HTMLElement) {
        super(rootElement);
        this._type = this.getType();
        this.initElements();
        this.initButton();
        this.initLabel();
        this.initListeners();
        CoreState.MENU_STATE.onChangeSignal.add(() => this.onMenuStateChanged());
    }

    /******************************************************************
     * Public Methodes
     *****************************************************************/

    public async updateStyles() {
        gsap.to(this.view, {
            duration: AppConfig.MENU_SHOW_HIDE_DURATION,
            left: this.calcShowButton() ? 0 : 20,
            opacity: this.calcShowButton() ? 1 : 0,
            pointerEvents: this.calcShowButton() ? "all" : "none",
            ease: "power4.out"
        })
        this.applyStyle({
            position: "relative",
            cursor: "pointer",
        })
        this._anchor.applyStyle({
            display: "none"
        })
        this._labelField?.applyStyle({
            display: this.showLabel() ? "block" : "none",
            position: "absolute",
            whiteSpace: "nowrap",
            top: "50%",
            left: this._button.defaultWidth,
            y: "-55%",
        })
        await PromisedDelay.wait(0.1);
        this.applyStyle({
            backgroundColor: Color.KKT_WHITE,
            borderRadius: 100,
            boxShadow: ShadowEffects.META_BUTTON_SHADOW,
            width: this.calcWidth(),
            height: this._button.defaultHeight
        })
    }

    /******************************************************************
     * Private Methodes
     *****************************************************************/

    private initElements() {
        this._anchor = new UIComponent<HTMLAnchorElement>(this.getElementByTag("a") as HTMLAnchorElement);
    }

    private initButton() {
        this._button = this.getButtonSVG();
        this.addChild(this._button);
    }

    private initLabel() {
        if (this._type != "phone" || this.view.dataset.showphonenumber == "false") return;
        this._labelField = new TextField({
            fontStyle: () => null,
            html: this._anchor.view.innerText
        })
        this.addChild(this._labelField);
    }

    private getButtonSVG(): SVGComponent {
        switch (this._type) {
            case "phone":
                return new SVGComponent(CoreAssets.PHONE_BUTTON);
            case "mail":
                return new SVGComponent(CoreAssets.MAIL_BUTTON);
            case "location":
                return new SVGComponent(CoreAssets.LOCATION_BUTTON);
            case "internalLink":
                return new SVGComponent(CoreAssets.INTERNAL_LINK_BUTTON);
        }
    }

    private getType(): ContactButtonType {
        switch (this.view.className) {
            case "kkt-phone-button":
                return "phone"
            case "kkt-mail-button":
                return "mail"
            case "kkt-location-button":
                return "location"
            case "kkt-internallink-button":
                return "internalLink"
        }
        return undefined
    }

    private initListeners() {
        this.addNativeListener("click", () => this.onClicked());
    }

    private showLabel(): boolean {
        return Size.APP_WIDTH > 1200;
    }

    private calcShowButton(): boolean {
        if (!Size.IS_MOBILE_MODE) {
            return true;
        }
        return CoreState.MENU_STATE.isValue("closed") || (AppConfig.IS_HOMEPAGE && !Size.IS_MOBILE_MODE);
    }

    private calcWidth() {
        let width = this._button.defaultWidth;
        if (this._labelField && this.showLabel()) {
            width += this._labelField.view.offsetWidth;
            width += 10;
        }
        return width;
    }

    /******************************************************************
     * Events
     *****************************************************************/

    private onClicked() {
        this._anchor.view.click();
    }

    private onMenuStateChanged() {
        this.updateStyles();
    }

}
