import {UIController} from "../UIController";
import {WheelController} from "@webfruits/toolbox/dist/controller/input/WheelController";
import {HomeTeaserController} from "./modules/HomeTeaserController";
import {CoreState} from "../../core/CoreState";

/******************************************************************
 * HomeTeaserController
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class HomepageController extends UIController {

    /******************************************************************
     * Properties
     *****************************************************************/

    private _scrollController: WheelController;
    private _teaserControllers: HomeTeaserController[] = [];

    /******************************************************************
     * Constructor
     *****************************************************************/

    constructor(bodyElement: HTMLElement) {
        super(bodyElement);
        this.initTeaserControllers();
        this.initScrollController();
        this.initState();
    }

    /******************************************************************
     * Public Methodes
     *****************************************************************/

    // no public methodes yet

    /******************************************************************
     * Private Methodes
     *****************************************************************/

    private initScrollController() {
        this._scrollController = new WheelController(this.getElementByClass("kkt-home-teasers"));
        this._scrollController.onDownSignal.add(() => this.onScrolledDown());
        this._scrollController.onUpSignal.add(() => this.onScrolledUp());
    }

    private initTeaserControllers() {
        const teaserElements = this.getElementsByClass("kkt-home-teaser");
        teaserElements.forEach((teaserElement: HTMLElement) => {
            this._teaserControllers.push(new HomeTeaserController(teaserElement));
        })
    }

    private initState() {
        CoreState.HOMEPAGE_TEASER_ID.setValue(this._teaserControllers[0].id);
    }

    private getNextTeaserID() {
        const currentTeaserID = CoreState.HOMEPAGE_TEASER_ID.getValue();
        const currentTeaserElement = this.getElementByID(currentTeaserID);
        if (currentTeaserElement.nextElementSibling) {
            return currentTeaserElement.nextElementSibling.id;
        }
        return this._teaserControllers[0].id;
    }

    private getPrevTeaserID() {
        const currentTeaserID = CoreState.HOMEPAGE_TEASER_ID.getValue();
        const currentTeaserElement = this.getElementByID(currentTeaserID);
        if (currentTeaserElement.previousElementSibling) {
            return currentTeaserElement.previousElementSibling.id;
        }
        return this._teaserControllers[this._teaserControllers.length - 1].id;
    }

    /******************************************************************
     * Events
     *****************************************************************/

    private onScrolledDown() {
        CoreState.HOMEPAGE_TEASER_ID.setValue(this.getNextTeaserID());
    }

    private onScrolledUp() {
        CoreState.HOMEPAGE_TEASER_ID.setValue(this.getPrevTeaserID());
    }

}
