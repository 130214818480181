import {UIController} from "../UIController";
import {SVGComponent} from "@webfruits/toolbox/dist/components/svg/SVGComponent";
import {CoreAssets} from "../../core/CoreAssets";
import {UIComponent} from "@webfruits/core";
import {PointerEventsController} from "../../utils/PointerEventsController";
import {gsap} from "gsap";
import {AccordionStateType} from "../../types/AccordionStateType";

/******************************************************************
 * AccordionEntryController
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class AccordionEntryController extends UIController {

    /******************************************************************
     * Properties
     *****************************************************************/

    private _plusIcon: SVGComponent;
    private _titleFrame: UIComponent;
    private _contentFrame: UIComponent;
    private _ctaFrame: UIComponent;
    private _plusIconVLine: SVGElement;
    private _pointerEventsController: PointerEventsController;

    /******************************************************************
     * Constructor
     *****************************************************************/

    constructor(rootElement: HTMLElement) {
        super(rootElement);
        this.initElements();
        this.initPlusIcon();
        this.initListeners();
        this.setState(this.getState(), false);
    }

    /******************************************************************
     * Public Methodes
     *****************************************************************/

    public getState(): AccordionStateType {
        return this.view.dataset.state as AccordionStateType;
    }

    public setState(state: AccordionStateType, animate: boolean = true) {
        this.view.dataset.state = state;
        const duration = animate ? 0.7 : 0;
        gsap.to(this._contentFrame.view, {
            duration: duration,
            height: state == "opened" ? "auto" : 0,
            ease: "power4.inOut"
        })
        gsap.to(this._plusIconVLine, {
            duration: duration,
            scaleY: state == "opened" ? 0 : 1,
            transformOrigin: "center",
            ease: "power4.inOut"
        })
        gsap.to(this._plusIcon.view, {
            duration: duration,
            y: "-50%",
            transformOrigin: "center",
            rotation: state == "opened" ? 180 : 0,
            ease: "power4.inOut"
        })
        this._ctaFrame.interactive = state == "opened";
        gsap.to(this._ctaFrame.view, {
            duration: duration,
            autoAlpha:  state == "opened" ? 1 : 0,
            ease: "power4.inOut"
        })
    }

    public updateStyles() {
        this._titleFrame.applyStyle({
            position: "relative",
            cursor: "pointer"
        });
        this._plusIcon.applyStyle({
            position: "absolute",
            right: parseInt(window.getComputedStyle(this._titleFrame.view).paddingLeft),
            top: "50%",
            y: "-50%"
        });
    }

    /******************************************************************
     * Private Methodes
     *****************************************************************/

    private initElements() {
        this._titleFrame = new UIComponent(this.getElementByClass("kkt-accordion-entry-title"));
        this._contentFrame = new UIComponent(this.getElementByClass("kkt-accordion-entry-content"));
        this._ctaFrame = new UIComponent(this.getElementByClass("kkt-accordion-entry-cta"));
    }

    private initPlusIcon() {
        this._plusIcon = new SVGComponent(CoreAssets.PLUS_ICON);
        this._plusIconVLine = this._plusIcon.getElementByID("vline");
        this._titleFrame.addChild(this._plusIcon);
    }

    private initListeners() {
        this._pointerEventsController = new PointerEventsController(this._titleFrame);
        this._pointerEventsController.onClickSignal.add(() => this.onPointerClicked());
    }

    /******************************************************************
     * Events
     *****************************************************************/

    private onPointerClicked() {
        this.setState(this.getState() == "opened" ? "closed" : "opened");
    }

}
