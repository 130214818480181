import {UIController} from "../../UIController";
import {gsap} from "gsap";
import {AppConfig} from "../../../config/AppConfig";
import {CoreState} from "../../../core/CoreState";

/******************************************************************
 * HomeTeaserController
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class HomeTeaserController extends UIController {

    /******************************************************************
     * Properties
     *****************************************************************/

    private _latestTeaserID: string = undefined;
    private _infoElement: HTMLElement;
    private _imageElement: HTMLImageElement;

    /******************************************************************
     * Constructor
     *****************************************************************/

    constructor(teaserElement: HTMLElement) {
        super(teaserElement)
        this.initElements();
        this.updateStyles();
        CoreState.HOMEPAGE_TEASER_ID.onChangeSignal.add(() => this.onTeaserIDStateChanged());
        CoreState.SECONDARY_NAV_VISIBLE.onChangeSignal.add(() => this.onSecondaryNavLayerStateChanged());
        CoreState.MENU_STATE.onChangeSignal.add(() => this.onMenuStateChanged());
    }

    /******************************************************************
     * Public Methodes
     *****************************************************************/

    get id(): string {
        return this.view.id;
    }

    public updateStyles() {
        if (this._infoElement) {
            gsap.set(this._infoElement, {
                autoAlpha: this.calcInfoElementAlpha()
            })
            gsap.set(this.getInfoElementChildren(), {
                autoAlpha: this.calcInfoElementChildrenAlpha()
            })
            if (this._infoElement.className == "kkt-teaser-infobox") {
                gsap.set(this._infoElement, {
                    x: "-50%"
                })
            }
        }
        gsap.set(this._imageElement, {
            autoAlpha: this.calcHeroImageAlpha()
        })
    }

    /******************************************************************
     * Private Methodes
     *****************************************************************/

    private initElements() {
        this._imageElement = this.getElementByTag("img") as HTMLImageElement;
        this._infoElement = this.getElementByClass("kkt-teaser-infobox")
            || this.getElementByClass("kkt-teaser-quote")
            || this.getElementByClass("kkt-contactbox");
    }

    private isCurrent(): boolean {
        return CoreState.HOMEPAGE_TEASER_ID.isValue(this.id);
    }

    private isNext(): boolean {
        const latestIndex = this.getIndexOfTeaserElementID(this._latestTeaserID);
        const newIndex = this.getIndexOfTeaserElementID(CoreState.HOMEPAGE_TEASER_ID.getValue());
        return newIndex > latestIndex;
    }

    private isPrev(): boolean {
        const latestIndex = this.getIndexOfTeaserElementID(this._latestTeaserID);
        const newIndex = this.getIndexOfTeaserElementID(CoreState.HOMEPAGE_TEASER_ID.getValue());
        return newIndex < latestIndex;
    }

    private getIndexOfTeaserElementID(id): number {
        const teaserList = Array.from(this.view.parentElement.querySelectorAll(".kkt-home-teaser"));
        let index: number = 0;
        teaserList.forEach((teaserElement: HTMLElement, i: number) => {
            if (teaserElement.id == id) {
                index = i;
            }
        })
        return index;
    }

    private getDefaultHeroImageOpacity(): number {
        return parseInt(this._imageElement.dataset.defaultopacity) / 100;
    }

    private calcHeroImageAlpha(): number {
        if (this.isCurrent()) {
            return this.getDefaultHeroImageOpacity();
        }
        return 0;
    }

    private calcInfoElementAlpha(): number {
        if (AppConfig.HIDE_NAVIGATION && !CoreState.MENU_STATE.isValue("closed")) {
            return 0;
        }
        if (this.isCurrent() && !CoreState.SECONDARY_NAV_VISIBLE.getValue()) {
            return 1;
        }
        return 0;
    }

    private calcInfoElementTargetY(): number {
        if (this.isCurrent()) {
            return 0;
        }
        if (this.isNext()) {
            return -100;
        }
        if (this.isPrev()) {
            return 100;
        }
        return 0;
    }

    private calcInfoElementStartObject(): Object {
        if (this.isCurrent() && (this.isNext() || this.isPrev())) {
            if (this.isNext()) {
                return {y: 20}
            }
            if (this.isPrev()) {
                return {y: -20}
            }
        }
        return {};
    }

    private calcInfoElementChildrenAlpha(): number {
        if (this.isCurrent()) {
            return 1;
        }
        return 0;
    }

    private calcInfoElementChildrenTargetY(): number {
        if (this.isCurrent()) {
            return 0;
        }
        if (this.isNext()) {
            return -100;
        }
        if (this.isPrev()) {
            return 100;
        }
        return 0;
    }

    private calcInfoElementChildrenStartObject(): Object {
        if (this.isCurrent() && (this.isNext() || this.isPrev())) {
            if (this.isNext()) {
                return {y: 20}
            }
            if (this.isPrev()) {
                return {y: -20}
            }
        }
        return {};
    }

    private animateHeroImage() {
        gsap.killTweensOf(this._imageElement);
        gsap.to(this._imageElement, {
            duration: AppConfig.HOME_TEASER_CHANGE_DURATION,
            autoAlpha: this.calcHeroImageAlpha(),
            ease: "power4.inOut"
        })
    }

    private animateInfoBox(animateAlphaOnly: boolean = false) {
        if (!this._infoElement) return;
        let duration = AppConfig.HOME_TEASER_CHANGE_DURATION * 0.5;
        let ease = this.isCurrent() ? "power4.out" : "power4.in";
        let delay = this.isCurrent() && !CoreState.SECONDARY_NAV_VISIBLE.getValue() ? duration : 0;
        if (CoreState.SECONDARY_NAV_VISIBLE.getValue() && this.isCurrent()) {
            duration = AppConfig.HOME_TEASER_CHANGE_DURATION;
        }
        if (animateAlphaOnly) {
            delay = 0;
            duration = AppConfig.MENU_SHOW_HIDE_DURATION;
            ease = "power3.inOut"
        }
        gsap.killTweensOf(this._infoElement);
        gsap.to(this._infoElement, {
            delay: delay,
            duration: duration,
            startAt: this.calcInfoElementStartObject(),
            autoAlpha: this.calcInfoElementAlpha(),
            y: this.calcInfoElementTargetY(),
            ease: ease
        })
    }

    private animateInfoBoxElements() {
        if (!this._infoElement) return;
        let children = this.getInfoElementChildren();
        if (!this.isNext()) {
            children = children.reverse();
        }
        const duration = AppConfig.HOME_TEASER_CHANGE_DURATION * 0.5;
        gsap.killTweensOf(children);
        gsap.to(children, {
            delay: this.isCurrent() ? duration : 0,
            duration: duration,
            autoAlpha: this.calcInfoElementChildrenAlpha(),
            startAt: this.calcInfoElementChildrenStartObject(),
            y: this.calcInfoElementChildrenTargetY(),
            stagger: 0.1,
            ease: this.isCurrent() ? "power4.out" : "power4.in"
        })
    }

    private getInfoElementChildren(): HTMLElement[] {
        let children = Array.from(this._infoElement.children);
        if (this._infoElement.className == "kkt-contactbox") {
            children = Array.from(this._infoElement.getElementsByTagName("div")[0].children);
        }
        return children as HTMLElement[];
    }

    /******************************************************************
     * Events
     *****************************************************************/

    private onTeaserIDStateChanged() {
        this.animateHeroImage();
        this.animateInfoBox();
        this.animateInfoBoxElements();
        this._latestTeaserID = CoreState.HOMEPAGE_TEASER_ID.getValue();
    }

    private onSecondaryNavLayerStateChanged() {
        this.animateInfoBox(true);
    }

    private onMenuStateChanged() {
        this.animateInfoBox(true);
    }

}
