import {UIComponent} from "@webfruits/core";
import {Tracking} from "../../tracking/Tracking";

/******************************************************************
 * CookieDisclaimer
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class CookieDisclaimer extends UIComponent {

    /******************************************************************
     * Properties
     *****************************************************************/

    private _message: string;
    private _acceptLabel: string;
    private _denyLabel: string;
    private _acceptButton: UIComponent;
    private _denyButton: UIComponent;

    /******************************************************************
     * Constructor
     *****************************************************************/

    constructor() {
        super("cookie-disclaimer");
        this.initProperties();
        this.initHTML();
        this.initButtonListeners();
    }

    /******************************************************************
     * Public Methodes
     *****************************************************************/

    // no public methodes yet

    /******************************************************************
     * Private Methodes
     *****************************************************************/

    private initProperties() {
        const headerElement = document.body.getElementsByTagName("header")[0];
        this._message = headerElement.dataset.cookieDislaimerMessage;
        this._acceptLabel = headerElement.dataset.cookieDislaimerAccept;
        this._denyLabel = headerElement.dataset.cookieDislaimerDeny;
    }

    private initHTML() {
        this.view.innerHTML = `
            <div class="kkt-cookie-disclaimer-background"></div>
            <div class="kkt-cookie-disclaimer-window">
                <div>${this._message}</div>
                <div class="kkt-cookie-disclaimer-window-buttons">
                    <a class="kkt-cookie-disclaimer-window-buttons-deny">${this._denyLabel}</a>
                    <a class="kkt-cookie-disclaimer-window-buttons-accept">${this._acceptLabel}</a>
                </div>
                
            </div>
        `;
    }

    private initButtonListeners() {
        this._acceptButton = new UIComponent(this.view.getElementsByClassName("kkt-cookie-disclaimer-window-buttons-accept")[0] as HTMLAnchorElement);
        this._acceptButton.addNativeListener("click", (e: MouseEvent) => this.onAcceptButtonClicked(e));
        this._denyButton = new UIComponent(this.view.getElementsByClassName("kkt-cookie-disclaimer-window-buttons-deny")[0] as HTMLAnchorElement);
        this._denyButton.addNativeListener("click", (e: MouseEvent) => this.onDenyButtonClicked(e));
    }

    private initTracking() {
        Tracking.init();
    }

    /******************************************************************
     * Events
     *****************************************************************/

    private onAcceptButtonClicked(e: MouseEvent) {
        e.preventDefault();
        this.initTracking();
        this.destroy();
    }

    private onDenyButtonClicked(e: MouseEvent) {
        e.preventDefault();
        Tracking.denyForThisSession();
        this.destroy();
    }
}
