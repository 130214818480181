import {gsap} from "gsap";
import {Draggable} from "gsap/Draggable";
import {InertiaPlugin} from "gsap/InertiaPlugin"
import {ScrollToPlugin} from "gsap/ScrollToPlugin";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import {ControllerFactory} from "./controller/ControllerFactory";
import {PromisedDelay} from "@webfruits/toolbox/dist/timer/PromisedDelay";
import {CookieDisclaimer} from "./components/cookiedisclaimer/CookieDisclaimer";
import {Tracking} from "./tracking/Tracking";

/******************************************************************
 * PlastischeChirurgieMain
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class PlastischeChirurgieMain {

    /******************************************************************
     * Properties
     *****************************************************************/

    /******************************************************************
     * Public Methodes
     *****************************************************************/

    static async init() {
        this.initGSAPPlugins();
        this.initCookieDisclaimer();
        this.initControllers();
        await PromisedDelay.wait(0.1);
        this.removeLoaderOverlay();
    }

    /******************************************************************
     * Private Methodes
     *****************************************************************/

    private static initGSAPPlugins() {
        gsap.registerPlugin(Draggable, InertiaPlugin, ScrollToPlugin, ScrollTrigger);
    }

    private static initCookieDisclaimer() {
        if (Tracking.trackingType == "none") {
            return;
        }
        if (Tracking.isTrackingAlreadyAccepted()) {
            Tracking.init();
            return;
        }
        if (Tracking.isAlreadyDeniedForThisSession()) {
            return;
        }
        document.body.appendChild(new CookieDisclaimer().view);
    }

    private static initControllers() {
        ControllerFactory.createComponentControllers();
    }

    private static removeLoaderOverlay() {
        const overlay = document.getElementById("kkt-pageloader-overlay");
        gsap.to(overlay, {
            duration: 0.5,
            alpha: 0,
            onComplete: () => {
                overlay.remove();
            }
        })
    }

    /******************************************************************
     * Events
     *****************************************************************/

    // no events yet
}
