import {UIController} from "../UIController";
import {UIComponent} from "@webfruits/core";
import {BasicNavigationItemController} from "../navigation/modules/BasicNavigationItemController";
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import {Size} from "../../styles/Size";

/******************************************************************
 * ArticleHeaderController
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class ArticleHeaderController extends UIController {

    /******************************************************************
     * Properties
     *****************************************************************/

    private _heroImage: UIComponent<HTMLImageElement>;
    private _subPageNav: HTMLElement;
    private _heroImageFrame: HTMLElement;

    /******************************************************************
     * Constructor
     *****************************************************************/

    constructor(rootElement: HTMLElement) {
        super(rootElement)
        this.initElements();
        this.initSubNavButtons();
        this.initScrollTrigger();
    }

    /******************************************************************
     * Public Methodes
     *****************************************************************/

    public updateStyles() {
        this._heroImage.applyStyle({
            opacity: parseInt(this._heroImage.view.dataset.defaultopacity) / 100
        })
        ScrollTrigger.refresh();
    }

    /******************************************************************
     * Private Methodes
     *****************************************************************/

    private initElements() {
        this._heroImageFrame = this.getElementByClass("kkt-article-header-image");
        this._heroImage = new UIComponent<HTMLImageElement>(this.getElementByTag("img"));
        this._subPageNav = this.getElementByTag("nav");
    }

    private initSubNavButtons() {
        if (this._subPageNav) {
            Array.from(this._subPageNav.getElementsByTagName("li")).forEach((liElement: HTMLLIElement) => {
                const navButton = new BasicNavigationItemController(liElement);
                navButton.preventDefaultClick = true;
                const anchorName = navButton.view.getElementsByTagName("a")[0].href.split("#")[1];
                navButton.onClickSignal.add(() => this.onNavButtonClicked(anchorName))
            })
        }
    }

    private initScrollTrigger() {
        ScrollTrigger.create({
            trigger: this._heroImage.view,
            start: "top",
            end: "top+=200%",
            scrub: 0,
            animation: gsap.to(this._heroImage.view, {
                y: Math.round(Size.APP_HEIGHT * 0.2),
                snap: {
                    y: 1
                },
            })
        });
    }

    /******************************************************************
     * Events
     *****************************************************************/

    private onNavButtonClicked(anchorName: string) {
        gsap.to(window, {
            duration: 1,
            scrollTo: {
                y: "#" + anchorName
            },
            ease: "power4.inOut"
        });
    }

}
