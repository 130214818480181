import {Size} from "../styles/Size";
import {MenuStateTypes} from "../types/MenuStateTypes";

/******************************************************************
 * AppConfig
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class AppConfig {

    /* ----------------------------------------------------------------
 	 * HOMEPAGE
 	 * --------------------------------------------------------------*/

    static readonly IS_HOMEPAGE = document.body.id == "kkt-homepage";
    static readonly HOME_TEASER_CHANGE_DURATION = 1;

    /* ----------------------------------------------------------------
 	 * MENU / NAVIGATION
 	 * --------------------------------------------------------------*/

    static readonly MENU_SHOW_HIDE_DURATION = 0.7;

    static get HIDE_NAVIGATION() {
        return !AppConfig.IS_HOMEPAGE || Size.IS_MOBILE_MODE;
    }

    static get INITIAL_MENU_STATE(): MenuStateTypes {
        if (AppConfig.HIDE_NAVIGATION) {
            return "closed"
        }
        return "primary";
    }
}
