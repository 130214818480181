/******************************************************************
 * Tracking
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/
import {UIComponent} from "@webfruits/core";

export class Tracking {

    /******************************************************************
     * Properties
     *****************************************************************/

    // no properties yet

    /******************************************************************
     * Constructor
     *****************************************************************/

    static init() {
        switch (this.trackingType) {
            case "ga":
                this.initGoogleAnalyticsScript();
                break;
            case "gtag":
                this.initGoogleTagManagerScript();
                break;
        }
        localStorage.setItem("tracking-accepted", "true");
    }

    /******************************************************************
     * Public Methodes
     *****************************************************************/

    static isTrackingAlreadyAccepted(): boolean {
        return localStorage.getItem("tracking-accepted") == "true";
    }

    static isAlreadyDeniedForThisSession() {
        return sessionStorage.getItem("tracking-denied") == "true";
    }

    static denyForThisSession() {
        sessionStorage.setItem("tracking-denied", "true");
    }

    static get trackingType(): "gtag" | "ga" | "none" {
        switch (this.headerElement.dataset.trackingType) {
            case "gtag":
                return "gtag"
            case "ga":
                return "ga"
            default:
                return "none"
        }
    }

    static get trackingID(): string {
        const id = this.headerElement.dataset.trackingId;
        if (id && id.length > 0) return id;
        return undefined;
    }

    /******************************************************************
     * Private Methodes
     *****************************************************************/

    private static get headerElement(): HTMLElement {
        return document.body.getElementsByTagName("header")[0] as HTMLElement;
    }

    private static initGoogleAnalyticsScript() {
        if (!this.trackingID) return;
        window['ga'] = window['ga'] || function () {
            (window['ga'].q = window['ga'].q || []).push(arguments)
        };
        window['ga'].l = +new Date;
        window['ga']('create', this.trackingID, 'auto');
        window['ga']('send', 'pageview');
        const script = new UIComponent<HTMLScriptElement>("script");
        script.view.async = true;
        script.view.src = `https://www.google-analytics.com/analytics.js`;
        document.body.appendChild(script.view)
    }

    private static initGoogleTagManagerScript() {
        if (!this.trackingID) return;
        const script = new UIComponent<HTMLScriptElement>("script");
        script.view.async = true;
        script.view.src = `https://www.googletagmanager.com/gtag/js?id=${this.trackingID}`;
        window['dataLayer'] = window['dataLayer'] || [];
        window['gtag'] = () => {
            // @ts-ignore
            window['dataLayer'].push(arguments);
        }
        window['gtag']('js', new Date());
        window['gtag']('config', this.trackingID);
        document.body.appendChild(script.view)
    }

    /******************************************************************
     * Events
     *****************************************************************/

    // no events yet

}
