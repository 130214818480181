import {UIController} from "../../UIController";
import {gsap} from "gsap";
import {BasicNavigationItemController} from "./BasicNavigationItemController";
import {CoreState} from "../../../core/CoreState";
import {Size} from "../../../styles/Size";
import {AppConfig} from "../../../config/AppConfig";
import {Draggable} from "gsap/Draggable";
import BoundsMinMax = Draggable.BoundsMinMax;
import {DOMUtils} from "@webfruits/toolbox/dist/utils/DOMUtils";
import {PromisedDelay} from "@webfruits/toolbox/dist/timer/PromisedDelay";
import {URLUtils} from "@webfruits/toolbox/dist/utils/URLUtils";

/******************************************************************
 * SecondaryNavLayer
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class SecondaryNavLayer extends UIController {

    /******************************************************************
     * Properties
     *****************************************************************/

    private _ulElement: HTMLElement;
    private _isShowing: boolean;
    private _latestUlElementHeight: number;
    private _draggable: Draggable;
    private _secondaryTitleElement: HTMLElement;

    /******************************************************************
     * Constructor
     *****************************************************************/

    constructor(layerElement: HTMLElement) {
        super(layerElement);
        this.initElements();
        this.initDraggable();
        CoreState.PRIMARY_NAV_SCROLL_Y.onChangeSignal.add(() => this.onPrimaryScrollYStateChanged());
    }

    /******************************************************************
     * Public Methodes
     *****************************************************************/

    get isShowing(): boolean {
        return this._isShowing;
    }

    public show() {
        CoreState.SECONDARY_NAV_VISIBLE.setValue(true);
        this._isShowing = true;
        this._draggable.applyBounds(this.calcDraggableBounds());
        this._draggable.update(true);
        gsap.to(this.view, {
            duration: AppConfig.MENU_SHOW_HIDE_DURATION,
            width: this.calcLayerWidth(),
            ease: AppConfig.HIDE_NAVIGATION ? "power4.out" : "power4.inOut"
        })
        gsap.to(this._ulElement, {
            duration: AppConfig.MENU_SHOW_HIDE_DURATION,
            x: this.calcULElementX(),
            ease: AppConfig.HIDE_NAVIGATION ? "power4.out" : "power4.inOut"
        })
        gsap.set(this._ulElement, {
            y: this.calcULElementY()
        })
    }

    public hide() {
        CoreState.SECONDARY_NAV_VISIBLE.setValue(false);
        this._isShowing = false;
        gsap.to(this.view, {
            duration: AppConfig.MENU_SHOW_HIDE_DURATION,
            width: this.calcLayerWidth(),
            ease: AppConfig.HIDE_NAVIGATION ? "power4.out" : "power4.inOut"
        })
        gsap.to(this._ulElement, {
            duration: AppConfig.MENU_SHOW_HIDE_DURATION,
            x: this.calcULElementX(),
            ease: AppConfig.HIDE_NAVIGATION ? "power4.out" : "power4.inOut"
        })
    }

    public async updateStyles() {
        gsap.set(this._ulElement, {
            width: this.calcULElementWidth()
        })
        await PromisedDelay.waitUntilValid(() => {
            const valid = this._latestUlElementHeight == DOMUtils.calcElementHeight(this._ulElement);
            this._latestUlElementHeight = DOMUtils.calcElementHeight(this._ulElement);
            return valid;
        })
        gsap.set(this._ulElement, {
            x: this.calcULElementX(),
            y: this.calcULElementY()
        })
    }

    /******************************************************************
     * Private Methodes
     *****************************************************************/

    private initElements() {
        this._ulElement = this.getElementByTag("ul");
        this._secondaryTitleElement = this.getElementByClass("kkt-navigation-main-secondary-title");
        const secondaryNavLinkElements = this.getElementsByTag("li");
        secondaryNavLinkElements.forEach((secondaryNavLinkElement: HTMLLIElement) => {
            const buttonController = new BasicNavigationItemController(secondaryNavLinkElement);
            buttonController.onClickSignal.add(() => {
                this.onPageLinkClicked(secondaryNavLinkElement.getElementsByTagName("a")[0] as HTMLAnchorElement)
            });
        })
    }

    private initDraggable() {
        this._draggable = Draggable.create(this._ulElement, {
            type: "y",
            bounds: this.calcDraggableBounds(),
            throwProps: true,
            cursor: "pointer",
            maxDuration: 0.5,
            edgeResistance: 0.9,
            onDragStart: () => {
                this.interactive = false
            },
            onDragEnd: () => {
                this.interactive = true
            }
        })[0];
    }

    private getComputedPadding() {
        return parseInt(window.getComputedStyle(this._ulElement).paddingBottom);
    }

    private calcULElementWidth(): number {
        let maxSpanWidth = 0;
        Array.from(this._ulElement.querySelectorAll("span")).forEach((spanElement: HTMLElement) => {
            spanElement.style.whiteSpace = "nowrap";
            if (spanElement.scrollWidth > maxSpanWidth) {
                maxSpanWidth = spanElement.scrollWidth;
            }
            spanElement.style.removeProperty('white-space');
        })
        maxSpanWidth += this.getComputedPadding() * 1.5;
        let maxWidth = AppConfig.HIDE_NAVIGATION ? Size.APP_WIDTH - this.getComputedPadding() : 500;
        if (maxSpanWidth > maxWidth) {
            maxSpanWidth = maxWidth;
        }
        return maxSpanWidth;
    }

    private calcULElementX() {
        return this._isShowing ? "0%" : "-100%";
    }

    private calcULElementY() {
        if (this.needScrolling()) {
            return this.getComputedPadding() - CoreState.PRIMARY_NAV_SCROLL_Y.getValue();
        }
        const parentY = this.view.parentElement.getBoundingClientRect().y;
        const offsetY = Size.APP_HEIGHT - this._latestUlElementHeight - parentY;
        const secondaryTitleHeight = DOMUtils.calcElementHeight(this._secondaryTitleElement);
        let y = parentY - secondaryTitleHeight;
        if (offsetY < 0) {
            y = parentY + offsetY - secondaryTitleHeight;
        }
        if (y < this.getComputedPadding()) {
            y = this.getComputedPadding();
        }
        return y - CoreState.PRIMARY_NAV_SCROLL_Y.getValue();
    }

    private calcLayerWidth() {
        return this._isShowing ? this._ulElement.offsetWidth : 0;
    }

    private calcScrollOffset() {
        if (this._latestUlElementHeight) {
            const offset = Size.APP_HEIGHT - this._latestUlElementHeight - this.getComputedPadding();
            return offset > 0 ? 0 : offset;
        }
        return 0;
    }

    private needScrolling(): boolean {
        return Size.APP_HEIGHT - (this.getComputedPadding() * 2 + this._latestUlElementHeight) < 0;
    }

    /******************************************************************
     * Events
     *****************************************************************/

    private onPrimaryScrollYStateChanged() {
        gsap.set(this._ulElement, {
            y: this.calcULElementY()
        })
    }

    private calcDraggableBounds(): BoundsMinMax {
        return {
            minX: 0,
            minY: this.calcULElementY() + this.calcScrollOffset(),
            maxX: 0,
            maxY: this.calcULElementY()
        };
    }

    private onPageLinkClicked(anchorElement: HTMLAnchorElement) {
        URLUtils.openLink(anchorElement.href, false);
    }
}
