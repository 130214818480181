import {UIController} from "../../UIController";
import {SecondaryNavLayer} from "./SecondaryNavLayer";
import {BasicNavigationItemController} from "./BasicNavigationItemController";
import {SVGComponent} from "@webfruits/toolbox/dist/components/svg/SVGComponent";
import {CoreAssets} from "../../../core/CoreAssets";
import {UIComponent} from "@webfruits/core";
import {gsap} from "gsap";
import {CoreState} from "../../../core/CoreState";
import {AppConfig} from "../../../config/AppConfig";
import {DeviceUtils} from "@webfruits/core/dist/utils/DeviceUtils";

/******************************************************************
 * PrimaryNavItemController
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class PrimaryNavItemController extends UIController {

    /******************************************************************
     * Properties
     *****************************************************************/

    private _secondarylayer: SecondaryNavLayer;
    private _navigationItem: BasicNavigationItemController;
    private _defaultPointer: SVGComponent;
    private _pointerBox: UIComponent;
    private _selectedPointer: SVGComponent;

    /******************************************************************
     * Constructor
     *****************************************************************/

    constructor(liElement: HTMLLIElement) {
        super(liElement);
        this.initNavigationItem();
        this.initSecondaryLayer();
        this.initPointer();
        CoreState.HOMEPAGE_TEASER_ID.onChangeSignal.add(() => this.onHomeTeaserStateChanged());
        CoreState.MENU_STATE.onChangeSignal.add(() => this.onMenuStateChanged());
    }

    /******************************************************************
     * Public Methodes
     *****************************************************************/

    public updateStyles() {
        this._pointerBox?.applyStyle({
            position: "absolute",
            zIndex: -1,
            top: 0,
            left: `calc(100% + ${this._navigationItem.spanWidthOffset}px)`
        })
        this._defaultPointer?.applyStyle({
            position: "absolute",
            top: 0,
            left: 0
        })
        this._selectedPointer?.applyStyle({
            position: "absolute",
            top: 0,
            left: 0
        })
    }

    /******************************************************************
     * Private Methodes
     *****************************************************************/

    private initNavigationItem() {
        this._navigationItem = new BasicNavigationItemController(this.view as HTMLLIElement);
        this._navigationItem.onEnterSignal.add(() => this.onEntered());
        this._navigationItem.onLeaveSignal.add(() => this.onLeft());
        this._navigationItem.onClickSignal.add(() => this.onClicked());
    }

    private initSecondaryLayer() {
        const layerElement = this.getElementByClass("kkt-navigation-main-secondary");
        if (layerElement) {
            if (layerElement.dataset.showinmenu == "true") {
                this._secondarylayer = new SecondaryNavLayer(layerElement);
            }
        }
    }

    private initPointer() {
        if (!AppConfig.IS_HOMEPAGE) return;
        this._pointerBox = new UIComponent("pointer-box");
        this._defaultPointer = new SVGComponent(CoreAssets.PRIMARY_NAV_TEASER_POINTER);
        this._selectedPointer = new SVGComponent(CoreAssets.PRIMARY_NAV_TEASER_POINTER_SELECTED);
        this.addChild(this._pointerBox);
        this._pointerBox.addChild(this._defaultPointer);
        this._pointerBox.addChild(this._selectedPointer);
    }

    private updatePointer() {
        if (!this._selectedPointer) return;
        gsap.to(this._selectedPointer.view, {
            duration: 0.4,
            x: this.isThisSelected() ? "0%" : "-100%",
            ease: "power4.inOut"
        })
    }

    private isThisSelected(): boolean {
        return CoreState.HOMEPAGE_TEASER_ID.isValue(this.view.dataset.linkedid);
    }

    /******************************************************************
     * Events
     *****************************************************************/

    private onClicked() {
        this._navigationItem.preventDefaultClick = false;
        if (this._navigationItem.isTouchEvent && this._secondarylayer) {
            this._navigationItem.preventDefaultClick = true;
            CoreState.MENU_STATE.setValue("secondary");
            this._secondarylayer.show();
        }
    }

    private onEntered() {
        if (this._secondarylayer && !DeviceUtils.IS_MOBILE) {
            this._secondarylayer.show();
        }
        CoreState.HOMEPAGE_TEASER_ID.setValue(this.view.dataset.linkedid);
    }

    private onLeft() {
        if (this._secondarylayer && !DeviceUtils.IS_MOBILE) {
            this._secondarylayer.hide();
        }
    }

    private onHomeTeaserStateChanged() {
        if (!this.isThisSelected() && this._secondarylayer?.isShowing) {
            this._secondarylayer.hide();
        }
        this.updatePointer();
    }

    private onMenuStateChanged() {
        if (DeviceUtils.IS_MOBILE
            && this._secondarylayer?.isShowing) {
            this._secondarylayer.hide();
        }
    }
}
